export default [
    { headerName: 'ID', field: 'id' },
    { headerName: 'LEA', field: 'lea', initialHide: true, filter: false },
    {
        headerName: 'School Year',
        field: 'schoolYear',
        initialHide: true,
        filter: false,
    },
    {
        headerName: 'Update Date',
        field: 'updateDate',
        filter: 'date',
    },
    { headerName: 'Is Deleted', field: 'isDeleted', filter: false },
    {
        headerName: 'School Code',
        field: 'schoolCode',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    {
        headerName: 'Student Id',
        field: 'studentId',
        filterParams: {
            filterOptions: ['contains', 'equals', 'startsWith', 'endsWith'],
        },
    },
    { headerName: 'Program Code', field: 'programCode', filter: false },
    {
        headerName: 'Program Description',
        field: 'programDescription',
        filter: false,
    },
    { headerName: 'Exit Code', field: 'exitCode', filter: false },
    { headerName: 'Exit Description', field: 'exitDescription', filter: false },
    { headerName: 'Eligibility Start', field: 'eligibilityStart', filter: false },
    { headerName: 'Eligibility End', field: 'eligibilityEnd', filter: false },
    { headerName: 'Program Start', field: 'programStart', filter: false },
    { headerName: 'Program End', field: 'programEnd', filter: false },
    { headerName: 'Extended Code', field: 'extendedCode', filter: false },
    {
        headerName: 'Extended Description',
        field: 'extendedDesciption',
        filter: false,
    },
]
